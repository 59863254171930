// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-bombeo-js": () => import("./../../../src/templates/bombeo.js" /* webpackChunkName: "component---src-templates-bombeo-js" */),
  "component---src-templates-canalizacion-aguas-js": () => import("./../../../src/templates/canalizacion-aguas.js" /* webpackChunkName: "component---src-templates-canalizacion-aguas-js" */),
  "component---src-templates-consejos-js": () => import("./../../../src/templates/consejos.js" /* webpackChunkName: "component---src-templates-consejos-js" */),
  "component---src-templates-energia-fotovoltaica-js": () => import("./../../../src/templates/energia-fotovoltaica.js" /* webpackChunkName: "component---src-templates-energia-fotovoltaica-js" */),
  "component---src-templates-jardineria-js": () => import("./../../../src/templates/jardineria.js" /* webpackChunkName: "component---src-templates-jardineria-js" */),
  "component---src-templates-riego-js": () => import("./../../../src/templates/riego.js" /* webpackChunkName: "component---src-templates-riego-js" */)
}

